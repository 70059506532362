import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ApiServiceService } from "../api-service.service";

@Component({
  selector: "app-contact-me",
  templateUrl: "./contact-me.component.html",
  styleUrls: ["./contact-me.component.css"],
})
export class ContactMeComponent implements OnInit {
  form: FormGroup;

  constructor(
    private serverService: ApiServiceService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      message: ["", Validators.required],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      let reqBody = {
        name: this.form.controls["name"].value,
        email: this.form.controls["email"].value,
        message: this.form.controls["message"].value,
      };
      this.serverService.sendData(reqBody).subscribe((response) => {
        if (response.Status == 200)
          this.toastr.success("Thank you for contacting me!", "Message Sent");
        else this.toastr.error("Some Error Occurred");
      });
    }
  }
}
