import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http: HttpClient) {}

  public downloadResume(): Observable<any> {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');

    return this.http.get(`/api/downloadResume`, {headers, responseType: 'blob' });
  }

  public sendData(data: any): Observable<any> {
    return this.http.post(`/api/contact`, data);
  }
}
