import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';


@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {

  constructor(private serverService: ApiServiceService) { }

  downloadResume(){
    this.serverService.downloadResume().subscribe(response => {
      this.saveFile(response);
    });
  }

  private saveFile(response: Blob): void {
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(response);
    downloadLink.href = url;
    downloadLink.download = 'resume.pdf';
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  

  ngOnInit(){

  }
  }


